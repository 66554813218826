import React, { createContext, useEffect, useState } from "react";
import { User } from "../core/types";
import { getUser } from "./UserLocalStorage";

export interface Props {
    children: React.ReactNode;
}

export class UserContextProps {
    user!: User | null;
    setUser!: React.Dispatch<React.SetStateAction<User|null>>
}

export const UserContext = createContext<UserContextProps>(new UserContextProps());

export default function UserProvider({ children }: Props) {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        setUser(getUser());
    }, []);
    return (
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    );
}