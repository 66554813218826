import { Alert, Snackbar, SnackbarCloseReason, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Toast, ToastContext } from "./ToastContextProvider";


interface Props {
    toast: Toast;
    index: number;
}

function ToastComp({ toast, index }: Props) {
    const [open, setOpen] = useState<boolean>(true);
    // Ensure the Snackbar opens whenever a new toast is added
    useEffect(() => {
        setOpen(true);
    }, [toast]);

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return <>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} sx={{ bottom: `${index * 60}px` }} >
            <Alert
                onClose={handleClose}
                severity={toast.severity}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {toast.message}
            </Alert>
        </Snackbar>
    </>
}

export default function ToastStack() {
    const { toasts } = useContext(ToastContext);
    return <>
        <Stack sx={{ width: '100%' }} spacing={2}>
            {toasts.map((t, index) => {
                return <ToastComp key={uuidv4()} index={index} toast={t} />
            })}
        </Stack>
    </>
}