import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, IconButton, Popover, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { PlacePeriod } from "../core/types";

export interface Props {
    periods: PlacePeriod[]
}

export default function OpeningHours({ periods }: Props) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const buildOpeningHoursComponent = (periods: PlacePeriod[]): React.ReactNode => {
        if (periods) {
            periods.map(p => p.open.day)
            return <Stack direction={"column"}>
                {periods.map(p => buildPlacePeriodComponent(p))}
            </Stack>
        }
        return <Typography>Open 24 hours</Typography>
    }

    const getWeekDay = (dayIndex: number): string => {
        var weekday = new Array(7);
        weekday[0] = "Monday";
        weekday[1] = "Tuesday";
        weekday[2] = "Wednesday";
        weekday[3] = "Thursday";
        weekday[4] = "Friday";
        weekday[5] = "Saturday";
        weekday[6] = "Sunday";
        return weekday[dayIndex];
    }

    const formatTime = (hour: number, minute: number) => {
        const adjustedHour = hour % 12 || 12;
        return `${hour}:${minute.toString().padStart(2, '0')}`;
    };

    const buildPlacePeriodComponent = (period: PlacePeriod): React.ReactNode => {
        if (period && period.open) {
            const openDay = getWeekDay(period.open.day);
            const openTime = formatTime(period.open.hour, period.open.minute);
            let closeTime = "00:00";
            if (period.close && period.close.day === period.open.day) {
                closeTime = formatTime(period.close.hour, period.close.minute);
            }
            return <Typography key={openTime + openDay} variant="body1">
                {openDay} {openTime} - {closeTime}
            </Typography>
        }
        return <></>
    }

    return <>
        <Tooltip title={"Opening hours"}>
            <IconButton onClick={handleClick}>
                <AccessTimeIcon></AccessTimeIcon>
            </IconButton>
        </Tooltip>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                {buildOpeningHoursComponent(periods)}
            </Box>
        </Popover>
    </>
}