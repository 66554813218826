import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Avatar, Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Itinerary, Place, User, VisitDay } from "../core/types";
import { HttpContext } from './HttpClientProvider';
import ItineraryComponent from "./Itinerary";
import ItineraryActions from './ItineraryActions';

export interface Props {
    slug: string;
}

export interface GetItineraryResponse {
    places: Place[];
    itinerary: Itinerary;
}

export default function ItineraryDetails() {
    const [loading, setLoading] = useState<boolean>(false);
    const [itineraryResponse, setItineraryResponse] = useState<GetItineraryResponse>();
    const navigate = useNavigate();
    const { httpClient } = useContext(HttpContext);
    const [author, setAuthor] = useState<User>();

    const { slug } = useParams();
    const getItinerary = () => {
        setLoading(true);
        httpClient.get("/itineraries/" + slug)
            .then(res => {
                setItineraryResponse(res.data);
            }).finally(() => setLoading(false))
    }

    useEffect(() => {
        getItinerary();
    }, [slug]);


    const places = itineraryResponse?.places ? itineraryResponse.places : [];
    const days = itineraryResponse?.itinerary.details.map(d => {
        return new VisitDay(new Date(d.date), places.filter(p => d.placeIds.includes(p.id)));
    });

    const buildDetailsComponent = (): React.ReactNode => {
        return <>
            <Box
                border={1}
                borderColor={(theme) => theme.palette.primary.main}
                borderRadius={2}
                padding={2}
                minWidth={"100%"}
                margin="auto" // Center the component horizontally
                bgcolor="background.paper"
                boxShadow={2}
            >
                <Stack spacing={2} alignItems="flex-start">
                    <Stack direction={"row"}>
                        {author && <>
                            <Stack direction={"row"}>
                                <Typography>{author.name}</Typography>
                                <Avatar src={author.pictureUrl}>{author.name}</Avatar>
                            </Stack>
                        </>}
                        <Typography
                            textTransform={"capitalize"}
                            variant="h5"
                            gutterBottom // Adds margin below
                        >
                            {itineraryResponse!.itinerary.name}
                        </Typography>
                    </Stack>

                    <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{
                            textAlign: 'justify', // Distribute text evenly across the width
                            wordBreak: 'break-word',
                            overflowY: 'auto', // Enable scrolling if content is too long
                            maxHeight: '10rem', // Limit height to avoid too much text at once
                            lineHeight: 1.6, // Improve readability with line spacing
                        }}
                    >
                        {itineraryResponse!.itinerary.description}
                    </Typography>
                </Stack>
            </Box>
        </>
    }


    return <>
        {loading && <LinearProgress />}
        {(days && itineraryResponse) && <>
            <Stack>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Button startIcon={<ArrowBackIcon />} onClick={() => navigate("/trips")}>
                        Trips
                    </Button>
                    <ItineraryActions itinerary={itineraryResponse.itinerary} />
                </Stack>
                {buildDetailsComponent()}
                <ItineraryComponent days={days ? days : []} selectedPlaces={places} onPlaceSelect={function (selected: boolean, place: Place): void {
                }} />
            </Stack>
        </>}
        {!days && !loading && <Box>
            <Typography>Itinerary does not exist or you don't have permission to see it.</Typography>
        </Box>}
    </>
}