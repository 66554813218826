import { PaletteMode } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';

export const themeOptions = (mode: PaletteMode | undefined): ThemeOptions => {
    return {
        palette: {
            mode: mode,
            primary: {
                //main: "#932BD8",
                main: "#1F84B5",
            },
            secondary: {
                //main: '#5B6AFF',
                main: '#2C6192',
            },
        },
        components: {
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                },
            },
            MuiTooltip: {
                defaultProps: {
                    arrow: true,
                }
            },
            MuiList: {
                defaultProps: {
                    dense: true,
                }
            },
            MuiMenuItem: {
                defaultProps: {
                    dense: true,
                }
            },
            MuiTable: {
                defaultProps: {
                    size: 'small',
                }
            },
            MuiButton: {
                defaultProps: {
                    size: 'small',
                }
            },
            MuiButtonGroup: {
                defaultProps: {
                    size: 'small',
                }
            },
            MuiCheckbox: {
                defaultProps: {
                    size: 'small',
                }
            },
            MuiFab: {
                defaultProps: {
                    size: 'small',
                }
            },
            MuiFormControl: {
                defaultProps: {
                    margin: 'dense',
                    size: 'small',
                }
            },
            MuiFormHelperText: {
                defaultProps: {
                    margin: 'dense',
                }
            },
            MuiIconButton: {
                defaultProps: {
                    size: 'small',
                }
            },
            MuiInputBase: {
                defaultProps: {
                    margin: 'dense',
                }
            },
            MuiInputLabel: {
                defaultProps: {
                    margin: 'dense',
                }
            },
            MuiRadio: {
                defaultProps: {
                    size: 'small',
                }
            },
            MuiSwitch: {
                defaultProps: {
                    size: 'small',
                }
            },
            MuiTextField: {
                defaultProps: {
                    margin: 'dense',
                    size: 'small',
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    colorInherit: {
                        backgroundColor: '#fff',
                        color: '#fff',
                    },
                }
            }
        },
        shape: {
            borderRadius: 4,
        },
        spacing: 8,
    };
}