import { Autocomplete, TextField, capitalize } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { HttpContext } from "../HttpClientProvider";

type CityProps = {
  label: string;
  minWidth?: string | number;
  onInput: (city: string | null) => void;
};

export default function CitySelectMinimal({
  label,
  minWidth,
  onInput,
}: CityProps) {
  const [searchCity, setSearchCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState<string[]>(["paris", "barcelona", "tokyo", "rome", "prague", "istanbul", "los angeles"]);

  const { httpClient } = useContext(HttpContext);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const controller = new AbortController();
      // If searchCity is 2 letters or more
      if (searchCity.length > 1) {
        setLoading(true);
        httpClient.get(`/cities?q=${searchCity}`, {
          signal: controller.signal,
        })
          .then((res) => {
            const result = res.data;
            setCities(result);
            setLoading(false);
          })
          .catch((e) => { });
      }

      return () => {
        try {
          controller.abort();
        } catch (error) {
          //slient
        }
      };
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchCity, 500]);

  const handleOnInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    event.preventDefault();
    setSearchCity(value);
  };

  return (
    <>
      <Autocomplete
        disablePortal
        //fullWidth
        id="combo-box-demo"
        options={cities}
        loading={loading}
        getOptionLabel={(c) => capitalize(c)}
        getOptionKey={(c) => c}
        isOptionEqualToValue={(option, value) => option === value}
        onInputChange={handleOnInputChange}
        onChange={(e, v, r, d) => {
          onInput(v);
        }}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder="Search" />
        )}
      />
    </>
  );
}
