import { IconButton, Stack, Typography } from "@mui/material";
import { Place } from "../core/types";
import MapFab from "./MapFab";
import { MapMarker } from "./Maps";
import PlaceCard from "./PlaceCard";

export interface Props {
    places: Place[];
    selectedPlaces: Place[];
    onPlaceSelect: (selected: boolean, place: Place) => void;
}

export default function PlacesMap({ places, selectedPlaces, onPlaceSelect }: Props) {
    if (!places) {
        return <></>
    }
    const centerPlace = places[0];
    const markers = places.map((p, i) => {
        return new MapMarker([p.latitude, p.longitude], p.name, `/number-${i + 1}.png`, p)
    })

    return <>
        <Stack direction={"column"} alignItems={"center"}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{places.length} places</Typography>
            <Stack
                spacing={1}
                margin={1}
                useFlexGap={true}
                justifyContent={"flex-start"}
                alignItems={'stretch'}
                alignContent={'flex-start'}
                flexWrap={'wrap'}
                direction={{
                    xs: "column",
                    md: "row",
                    xl: "row"
                }}>
                {places.map(p => {
                    return <>
                        <PlaceCard
                            key={p.id}
                            place={p} mini={true} onSelect={onPlaceSelect}
                            selected={selectedPlaces.some(place => place.id === p.id)}
                            overflow={<IconButton
                        
                                sx={{
                                    position: 'absolute',
                                    top: 8,
                                    left: 8,
                                    //backgroundColor: (theme) => theme.palette.primary.main,
                                    backgroundColor: "white",
                                }}>
                                <img width="24px" height={"24px"} src={`/number-${places.indexOf(p) + 1}.png`}></img>
                            </IconButton>}
                        />
                    </>
                })}
            </Stack >
            <MapFab onPlaceSelect={onPlaceSelect} markers={markers} center={[centerPlace.latitude, centerPlace.longitude]} zoom={13}></MapFab>
        </Stack>
    </>
}