import FavoriteIcon from '@mui/icons-material/Favorite';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Divider, Fab, IconButton, LinearProgress, Stack, Tooltip, Typography, keyframes } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Place, VisitDay } from "../core/types";
import { HttpContext } from './HttpClientProvider';
import Itinerary from './Itinerary';
import PlaceCard from "./PlaceCard";
import PlaceCategories from "./PlaceCategories";
import SaveItinerary from './SaveItinerary';
import SearchPlace from './SearchPlace';
import SelectedPlaces from './SelectedPlaces';
import TripPlannerStarter from "./TripPlannerStarter";

enum Step {
    Destination = "DESTINATION",
    Selection = "SELECTION",
    Itinerary = "ITINERARY",
}

export default function PlacesOfInterest() {
    const [step, setStep] = useState<Step>(Step.Destination);
    const [city, setCity] = useState<string>();
    const [keyword, setKeyword] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [types, setTypes] = useState<string[]>(["tourist_attraction"]);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [places, setPlaces] = useState<Place[]>([]);
    const [selectedPlaces, setSelectedPlaces] = useState<Place[]>([]);
    const [visitDays, setVisitDays] = useState<VisitDay[]>([]);
    const { httpClient } = useContext(HttpContext);


    useEffect(() => {
        if (city) {
            applyFilter();
        }
    }, [city, types, keyword]);

    const createItinerary = () => {
        setLoading(true);
        httpClient.post("/plan", {
            placeIds: selectedPlaces.map(p => p.id),
            startDate: startDate,
            endDate: endDate
        }).then((res) => {
            const result = res.data;
            const casted = result.days.map((day: { date: string | number | Date; places: Place[]; }) => {
                return new VisitDay(new Date(day.date), day.places);
            })
            setVisitDays(casted);
        }).finally(() => setLoading(false));
    }

    const applyFilter = () => {
        setLoading(true);
        httpClient.post("/places/search", {
            city: city,
            keyword: keyword ? keyword : null,
            oneOfTypes: types.length === 0 ? null : types
        }).then((res) => {
            const result = res.data;
            setPlaces(result);
        }).finally(() => setLoading(false));
    };

    const buildHeader = (): React.ReactNode => {
        const pulse = keyframes`
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
        `;
        return <>
            {loading && <LinearProgress color="secondary" />}
            {step === Step.Destination ? <></> :
                /*<AppBar sx={{bgcolor: (theme) => theme.palette.mode === "light" ? theme.palette.secondary.main : undefined}} position="sticky">
                    
                </AppBar>*/
                <Stack width={"100%"} position={"sticky"} borderRadius={'1px'}
                    spacing={4}
                    direction={'row'}
                    flexWrap={'wrap'}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <Stack alignItems={"center"} margin={1} spacing={2} direction={'row'}>
                        <IconButton color="inherit" sx={{ height: "10%" }} onClick={(e) => {
                            if (step === Step.Selection) {
                                setPlaces([])
                                setTypes([])
                                setCity(undefined)
                                setSelectedPlaces([])
                                setLoading(false)
                                setStep(Step.Destination);
                            }
                            if (step === Step.Itinerary) {
                                setStep(Step.Selection);
                            }
                        }}>
                            <KeyboardBackspaceIcon></KeyboardBackspaceIcon>
                        </IconButton>
                        <Stack margin={"20px"} spacing={1} height={"80px"} direction={'column'}>
                            <Typography variant='h6' textTransform={'capitalize'}>{city}</Typography>
                            <Typography >{startDate?.toDateString()} - {endDate?.toDateString()}</Typography>
                        </Stack>
                    </Stack>
                    {step === Step.Selection && (
                        <Stack direction={"column"}>
                            <Stack direction={"row"} spacing={1} margin={1}>
                                <Typography fontWeight={"bold"}>Select places to create itinerary</Typography>
                                <FavoriteIcon
                                    sx={{
                                        animation: `${pulse} 1.5s infinite`,
                                    }}
                                    color={"error"}></FavoriteIcon>

                            </Stack>
                            <Stack margin={1} spacing={1} alignItems={"center"} justifyContent={"left"} direction={"row"}>
                                <PlaceCategories categorySelected={(selection) => {
                                    setTypes(selection);
                                }}></PlaceCategories>
                                <SearchPlace keywordChange={(keyword) => { setKeyword(keyword) }}></SearchPlace>
                            </Stack>
                        </Stack>
                    )}
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                        {step === Step.Selection && (
                            <>
                                <SelectedPlaces selectedPlaces={selectedPlaces} onPlaceSelect={onPlaceSelect} />
                                <Button disabled={selectedPlaces.length - 1 < (endDate!.getDay() - startDate!.getDay())} variant='contained' endIcon={<SendIcon />} onClick={(event) => {
                                    event.preventDefault();
                                    createItinerary();
                                    setStep(Step.Itinerary);
                                }}>Create Itinerary</Button>
                            </>
                        )}
                        {step === Step.Itinerary && (
                            <Stack spacing={1} margin={1} direction={"row"}>
                                <SaveItinerary days={visitDays} />
                                <Tooltip title={"Chage plan"}>
                                    <Button sx={{ textTransform: "none" }} variant="outlined"
                                        onClick={() => { createItinerary() }} endIcon={<RefreshIcon />}>
                                        Readjust
                                    </Button>
                                </Tooltip>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            }
        </>
    }

    const buildDestinationComponent = (): React.ReactNode => {
        return <>
            <TripPlannerStarter onSubmit={(city, startDate, endDate) => {
                setCity(city);
                setStartDate(startDate);
                setEndDate(endDate);
                setStep(Step.Selection);
                //applyFilter();
            }}></TripPlannerStarter>
        </>
    }

    const buildItineraryComponent = (): React.ReactNode => {
        return <>
            {visitDays.length > 0 && <Itinerary onPlaceSelect={onPlaceSelect}
                selectedPlaces={selectedPlaces}
                days={visitDays.sort((d1, d2) => d1.date.getTime() - d2.date.getTime())}></Itinerary>}
        </>
    }

    const buildPlacesContainer = (): React.ReactNode => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Smooth scrolling
            });
        };
        return <Stack
            spacing={1}
            margin={1}
            useFlexGap={true}
            justifyContent={"center"}
            alignItems={'stretch'}
            alignContent={'flex-start'}
            direction={'row'}
            flexWrap={'wrap'}>
            {places.map(p => {
                return <PlaceCard key={p.id} mini={false} place={p} selected={selectedPlaces.some(item => item.id === p.id)} onSelect={onPlaceSelect}></PlaceCard>
            })}
            <Fab sx={{
                width: '40px',
                borderRadius: '12px',
                height: '40px',
                textTransform: 'none',
                position: 'fixed',
                top: '90%',     // Position from the top
                right: '1%',    // Position from the left
                transform: 'translate(-50%, -50%)',  // Move it to the exact center
                zIndex: 1000 // ensures it stays above other elements
            }} onClick={() => scrollToTop()}>
                <KeyboardArrowUpIcon color="primary"></KeyboardArrowUpIcon>
            </Fab>
        </Stack>
    }

    const buildSelectionComponent = (): React.ReactNode => {
        return <>
            <Box>
                {buildPlacesContainer()}
            </Box>
        </>
    }

    return (
        <>
            <Stack>
                {buildHeader()}
                <Divider sx={{ color: (theme) => theme.palette.primary.main }}></Divider>
                {step === Step.Destination && (
                    buildDestinationComponent()
                )}
                {step === Step.Selection && (
                    buildSelectionComponent()
                )}
                {step === Step.Itinerary && (
                    buildItineraryComponent()
                )}
            </Stack>
        </>
    )

    function onPlaceSelect(selected: boolean, place: Place) {
        if (selected) {
            if (!selectedPlaces.some(p => p.id === place.id)) {
                setSelectedPlaces((prev) => {
                    const updated = [...prev];
                    updated.push(place);
                    return updated;
                });
            }
        } else {
            removeSelection(place.id);
        }
    }

    function removeSelection(placeId: string) {
        setSelectedPlaces((prev) => {
            const updated = [...prev];
            return updated.filter(selectedPlace => selectedPlace.id !== placeId);
        });
    }
}