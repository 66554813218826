import React, { createContext, useCallback, useState } from 'react';

export interface Props {
    children: React.ReactNode;
}

export interface Toast {
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error';
}

// Properly typed context, default value needs to be specified
interface ToastContextProps {
    toasts: Toast[];
    addToast: (toast: Toast) => void;
}

// Create the context with default values
export const ToastContext = createContext<ToastContextProps>({
    toasts: [],
    addToast: () => { },
});

export default function ToastContextProvider({ children }: Props) {
    const [toasts, setToasts] = useState<Toast[]>([]);

    const addToast = useCallback((toast: Toast) => {
        setToasts((prev) => {
            const clone = [...prev];
            clone.push(toast);
            return clone.slice(-1);
        });
    }, []);

    const contextValue = {
        toasts: toasts,
        //addToast: useCallback((error: React.ReactNode) => addToast(error), [addToast]),
        addToast: (toast: Toast) => addToast(toast),
    };

    return (
        <ToastContext.Provider value={contextValue}>
            {children}
        </ToastContext.Provider>
    );
}