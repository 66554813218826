import MapIcon from '@mui/icons-material/Map';
import MenuIcon from '@mui/icons-material/Menu';
import { Dialog, DialogContent, Fab } from "@mui/material";
import { useState } from "react";
import Maps, { MapProps } from './Maps';


export default function MapFab({ center, zoom, markers, onPlaceSelect }: MapProps) {

    const [open, setOpen] = useState<boolean>(false)

    return <>
        <Fab size="large" sx={{
            width: '80px',
            borderRadius: '12px',
            height: '40px',
            textTransform: 'none',
            position: 'fixed',
            top: '90%',     // Position from the top
            left: '50%',    // Position from the left
            transform: 'translate(-50%, -50%)',  // Move it to the exact center
            zIndex: 1000 // ensures it stays above other elements
        }} onClick={() => setOpen(true)}>
            <MapIcon />
            Map
        </Fab>
        <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
            <DialogContent>
                <Fab size="large" sx={{
                    width: '80px',
                    borderRadius: '12px',
                    height: '40px',
                    textTransform: 'none',
                    position: 'fixed',
                    top: '90%',     // Position from the top
                    left: '50%',    // Position from the left
                    transform: 'translate(-50%, -50%)',  // Move it to the exact center
                    zIndex: 1000 // ensures it stays above other elements
                }} onClick={() => setOpen(false)}>
                    <MenuIcon />
                    List
                </Fab>
                <Maps center={center} zoom={zoom} markers={markers} onPlaceSelect={onPlaceSelect}></Maps>
            </DialogContent>
        </Dialog>
    </>
}