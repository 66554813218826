import { Logout } from "@mui/icons-material";
import { Button, Container, Stack, Typography } from "@mui/material";
import { Theme, useTheme } from '@mui/material/styles';
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import React, { useContext } from "react";
import { User } from "../core/types";
import { HttpContext } from "./HttpClientProvider";
import { UserContext } from "./UserContext";
import { saveUser } from "./UserLocalStorage";

export default function OAuthSignInPage() {
    const { user, setUser } = useContext(UserContext);
    const theme = useTheme<Theme>();
    const { httpClient } = useContext(HttpContext);
    const handleSuccess = (credentialResponse: CredentialResponse) => {
        httpClient.post("/google", {
            code: credentialResponse.credential
        })
            .then(res => {
                const user: User = res.data.user;
                setUser(user);
                saveUser(user);
            })
            .catch(error => {
            });
    };

    const handleLogout = () => {
        saveUser(null);
        setUser(null);
        httpClient.get("/logout");
    }

    const handleError = () => {
        //console.log('Login Failed');
    };

    const buildWelcomeComponent = (): React.ReactNode => {
        return <>
            <Stack spacing={1} margin={1} alignItems={"center"}>
                <Typography variant="h6" textTransform={"capitalize"}>Welcome {user?.name}</Typography>
                <Button variant="contained" onClick={handleLogout} endIcon={<Logout />}>Logout</Button>
            </Stack>
        </>
    }

    const buildLoginComponent = (): React.ReactNode => {
        return <>
            <Stack spacing={1} margin={1} alignItems={"center"}>
                <Typography variant="h6">Welcome to Journey Juggler</Typography>
                <GoogleLogin
                    theme={theme.palette.mode === "dark" ? "filled_black" : "outline"}
                    onSuccess={handleSuccess}
                    onError={handleError}
                />
            </Stack>
        </>
    }

    return <>
        <Container>
            {user ? buildWelcomeComponent() : buildLoginComponent()}
        </Container>
    </>;
}