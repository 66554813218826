import { ThemeProvider } from "@emotion/react";
import NightlightIcon from '@mui/icons-material/Nightlight';
import { CssBaseline, IconButton, PaletteMode, createTheme } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { themeOptions } from "./AppTheme";
import AboutUs from "./components/AboutUs";
import AppMenu from "./components/AppMenu";
import HttpContextProvider from "./components/HttpClientProvider";
import ItineraryDetails from "./components/ItineraryDetails";
import ListItineraries from "./components/ListItineraries";
import PlacesOfInterest from "./components/PlacesOfInterest";
import OAuthSignInPage from "./components/SignIn";
import { ToastContext } from "./components/ToastContextProvider";
import ToastStack from "./components/ToastStack";
import UserProvider from "./components/UserContext";



function App() {
  const [mode, setMode] = useState<PaletteMode>("dark");
  const { addToast } = useContext(ToastContext);
  const theme = useMemo(
    () =>
      createTheme(themeOptions(mode)),
    [mode]
  );

  const buildThemeToggleButton = (): React.ReactNode => {
    return <>
      <IconButton onClick={() => {
        if (mode === "dark") {
          setMode("light")
        } else {
          setMode("dark")
        }
      }}>
        <NightlightIcon />
      </IconButton>
    </>
  }

  useEffect(() => {
    const handleThemeChange = (e: MediaQueryListEvent) => {
      setMode(e.matches ? 'dark' : 'light');
    };

    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
    matchMedia.addEventListener('change', handleThemeChange);

    return () => {
      matchMedia.removeEventListener('change', handleThemeChange);
    };
  }, []);
  return (
    <React.Fragment>
      <GoogleOAuthProvider clientId="288768177053-5dv7911mo4of4ihj8bfn14f7a56gmqtd.apps.googleusercontent.com">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <HttpContextProvider>
            <UserProvider>
              <AppMenu themeToggle={buildThemeToggleButton()} />
              <ToastStack />
              <Routes>
                <Route path="/" element={<PlacesOfInterest />} />
                <Route path="/trips" element={<ListItineraries />} />
                <Route path="trips/:slug" element={<ItineraryDetails />} />
                <Route path="/login" element={<OAuthSignInPage />} />
                <Route path="/about" element={<AboutUs />} />
              </Routes>
            </UserProvider>
          </HttpContextProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </React.Fragment>
  );
}

export default App;
