
import { AxiosInstance } from 'axios';
import React, { createContext, useContext } from 'react';
import axiosClient from '../core/axiosclient';
import { Toast, ToastContext } from './ToastContextProvider';


function buildClient(addToast: (toast: Toast) => void): AxiosInstance {
    console.log("interceptor is added!");
    axiosClient.interceptors.response.use(
        (response) => {
            // If the response is successful, just return it
            return response;
        },
        (error) => {
            // Handle error responses
            if (error.response) {
                const { status } = error.response;

                // Handle 401 and 403 errors
                if (status === 401 || status === 403) {
                    window.location.href = "/login"; // Redirect to login on 401/403
                } else {
                    // Handle all other errors, show a toast notification
                    addToast({ message: `Error: ${error.response.data.message || "Something went wrong"}`, severity: "error" });
                }
            } else {
                // Network or other client-side errors
                //addToast({ message: "Network error. Please try again.", severity: "error" });
            }

            // Reject the error so the calling function knows there was an error
            return Promise.reject(error);
        }
    );
    return axiosClient;
}

export interface Props {
    children: React.ReactNode;
}
interface HttpContextProps {
    httpClient: AxiosInstance;
}

export const HttpContext = createContext<HttpContextProps>({
    httpClient: axiosClient
});

export default function HttpContextProvider({ children }: Props) {
    const { addToast } = useContext(ToastContext);
    const contextValue = {
        httpClient: buildClient(addToast)
    };

    return (
        <HttpContext.Provider value={contextValue}>
            {children}
        </HttpContext.Provider>
    );
}