import { Card, CardActionArea, CardContent, Divider, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Itinerary } from '../core/types';
import { UserContext } from './UserContext';

type ItineraryCardProps = {
    itinerary: Itinerary;
};

const ItinerarySummaryCard: React.FC<ItineraryCardProps> = ({ itinerary }) => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    return (
        <Card sx={{
            flexWrap: "wrap", width: "100%", boxShadow: 3, borderRadius: 2, bgcolor: 'background.paper'
        }}>
            <CardActionArea onClick={() => navigate(itinerary.slug)}>
                <CardContent sx={{ flexWrap: "wrap" }}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography gutterBottom variant="h5" component="div">
                            {itinerary.name}
                        </Typography>
                    </Stack>

                    <Typography variant="body2" color="text.secondary" sx={{
                        mt: 1, mb: 1, flexWrap: "wrap", maxHeight: '4rem', // Set max height here
                        overflowY: 'auto',
                        wordBreak: 'break-word'
                    }}>
                        {itinerary.description}
                    </Typography>

                    {itinerary.publishedAt && <Typography variant="body2" color="text.secondary">
                        Published: {new Date(itinerary.publishedAt).toLocaleDateString()}
                    </Typography>}
                    <Typography variant="body2" color="text.secondary">
                        Created: {new Date(itinerary.createdAt).toLocaleDateString()}
                    </Typography>

                    <Divider sx={{ my: 2 }} />

                    <Stack direction="row" spacing={2}>
                        <Typography variant="subtitle2" color="text.primary">
                            {itinerary.details.length} Day(s)
                        </Typography>
                        <Typography variant="subtitle2" color="text.primary">
                            {itinerary.details.reduce((acc, day) => acc + day.placeIds.length, 0)} Place(s)
                        </Typography>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default ItinerarySummaryCard;