import { Avatar, Box, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const AboutUs = () => {
    const theme = useTheme(); // Access the theme colors

    return (
        <Box
            width={"100%"}
            height={"100%"}
            padding={6}
            sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius: '12px',
                margin: 'auto',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'
            }}
        >
            {/* Company Introduction */}
            <Typography
                variant="h3"
                gutterBottom
                sx={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme.palette.primary.main
                }}
            >
                About Me
            </Typography>
            <Divider sx={{ marginY: 3 }} />
            <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '1.1rem', textAlign: 'center', color: theme.palette.text.primary }}
            >
                Hi, I'm the sole developer behind this application. I'm passionate about crafting innovative solutions,
                and this project is the culmination of my skills and dedication.
                I've always been passionate about traveling and exploring different cultures and places. 
                Through my own experiences, I recognized the need for a tool that helps organize trips efficiently. 
                That's why I decided to develop a platform that enables users to find the best ways to plan and optimize their travel itineraries.
            </Typography>

            {/* About the Developer */}
            <Stack alignItems="center" spacing={2} sx={{ marginTop: 2 }}>
                <Avatar
                    alt="Eren Kızılay"
                    src="/eren2.jpeg"
                    sx={{
                        width: 120,
                        height: 120,
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                    }}
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                    Eren KIZILAY
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Software Engineer
                </Typography>
            </Stack>

            {/* Social Media Links */}
            <Box textAlign="center" marginTop={4}>
                <IconButton
                    component="a"
                    href="https://www.linkedin.com/in/eren-kızılay-11b380160"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <LinkedInIcon color="primary" fontSize="large" />
                </IconButton>
                <IconButton
                    component="a"
                    href="https://github.com/ErenKizilay"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <GitHubIcon color='primary' fontSize="large" />
                </IconButton>
            </Box>
        </Box>
    );
};

export default AboutUs;
