import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Stack, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Itinerary, VisitDay } from "../core/types";
import { HttpContext } from './HttpClientProvider';
import { UserContext } from "./UserContext";

export interface Props {
    days: VisitDay[];
}

export default function SaveItinerary({ days }: Props) {
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const { user } = useContext(UserContext);
    const { httpClient } = useContext(HttpContext);
    const navigate = useNavigate();

    const maxNameLength = 20;
    const maxDescLength = 200;

    const saveItinerary = (): Promise<Itinerary> => {
        return httpClient.post("/auth/itineraries", {
            name: name,
            userId: user?.id,
            description: description,
            city: days[0].places[0].city,
            days: days.map(d => ({ date: d.date, placeIds: d.places.map(p => p.id) })),
        })
            .then((res) => {
                return res.data as Itinerary;  // Return the data directly
            })
            .catch((error) => {
                return Promise.reject(error);
            }).finally(() => setLoading(false));
    };

    const nameValid = (): boolean => {
        return name.length > 0 && name.length < maxNameLength;
    }

    const descriptionValid = (): boolean => {
        return description.length > 0 && description.length < maxDescLength;
    }

    const handleClose = () => {
        setOpen(false);
        setName("");
        setDescription("");
    }

    const handleSave = async () => {
        setLoading(true);
        const itinerary = await saveItinerary();
        handleClose();
        navigate("/trips/" + itinerary.slug)
    }
    return <>
        {user && <>
            <Button variant="outlined"
                onClick={() => { setOpen(true) }}>
                Save
            </Button>
            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Save Itinerary</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Stack spacing={1} margin={1}>
                        <TextField error={!nameValid()} helperText={`${name.length}/${maxNameLength}`} onChange={(e) => setName(e.target.value)} label={"Name"} />
                        <TextField multiline minRows={3} error={!descriptionValid()} helperText={`${description.length}/${maxDescLength}`} onChange={(e) => setDescription(e.target.value)} label={"Description"} />
                        <Button variant="contained" disabled={loading || !(nameValid() && descriptionValid())} onClick={() => {
                            handleSave();
                        }}>Save</Button>
                        {loading && <LinearProgress></LinearProgress>}
                    </Stack>
                </DialogContent>
            </Dialog>
        </>}
        {!user && <>
            <Button variant="contained" onClick={() => navigate("/login")}>
                Login to Save
            </Button>
        </>}
    </>
}