import { LatLngExpression, icon } from "leaflet";
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import { Place } from "../core/types";
import PlaceCard from "./PlaceCard";

export interface MapProps {
    center: LatLngExpression;
    zoom: number;
    markers: MapMarker[];
    onPlaceSelect: (selected: boolean, place: Place) => void;
}

export class MapMarker {
    position!: LatLngExpression;
    title!: string;
    place?: Place;
    iconUrl?: string;
    constructor(position: LatLngExpression, title: string, iconUrl: string, place: Place) {
        this.position = position;
        this.title = title;
        this.place = place;
        this.iconUrl = iconUrl;
    }
}

export default function Maps({ center, zoom, markers, onPlaceSelect }: MapProps) {
    return <>
        <MapContainer id="map" style={{ height: '100%', WebkitFontSmoothing: 'antialiased', MozOsxFontSmoothing: 'grayscale' }}
            center={center} zoom={zoom} scrollWheelZoom={true}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=dfekpNDkPofdRU0vtLsx"
            //url="https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=d3985352277e4a2e9f24664877d16eb1"
            />
            {markers.map(marker => {
                return <Marker
                    icon={marker.iconUrl ? icon({ iconUrl: marker.iconUrl, iconSize: [20, 20] }) : undefined}
                    key={marker.title} title={marker.title} position={marker.position}>
                    {marker.place && <Tooltip permanent={false}>
                        <PlaceCard place={marker.place}
                            withdMultiplier={0.65}
                            selected={false} mini={true}
                            onSelect={onPlaceSelect} />
                    </Tooltip>}

                </Marker>
            })}
        </MapContainer>
    </>
}