import AddIcon from '@mui/icons-material/Add';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRighIcon from '@mui/icons-material/ArrowRight';
import { Button, Divider, IconButton, LinearProgress, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Itinerary } from "../core/types";
import CitySelectMinimal from "./City/CitySelectMinimal";
import { HttpContext } from './HttpClientProvider';
import ItinerarySummaryCard from "./ItinerarySummaryCard";
import { UserContext } from "./UserContext";

enum ItineraryType {
    Mine = "MINE",
    Public = "PUBLIC"
}


export default function ListItineraries() {
    const [itineraries, setItineraries] = useState<Itinerary[]>([]);
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [type, setType] = useState<ItineraryType>(user ? ItineraryType.Mine : ItineraryType.Public);
    const [cursors, setCursors] = useState<(string | null)[]>([]);
    const [city, setCity] = useState<string | null>();
    const [keyword, setKeyword] = useState<string | null>(null);
    const { httpClient } = useContext(HttpContext);
    const navigate = useNavigate();

    const pageSize = 20;

    const pushCursor = (cursor: string | null) => {
        setCursors((prev) => {
            if (!prev.includes(cursor)) {
                const clone = [...prev];
                clone.push(cursor);
                //return clone.slice(-3);
                return clone;
            } else {
                return prev;
            }
        })
    }

    const popCursor = () => {
        setCursors((prev) => {
            const clone = [...prev];
            clone.pop();
            //clone.slice(-3);
            return clone;
        })
    }

    const getCursor = (forward: boolean): string | null => {
        if (forward) {
            return cursors[cursors.length - 1]
        } else {
            if (cursors.length > 2) {
                return cursors[cursors.length - 3];
            } else {
                return null;
            }
        }
    }
    const clearCursors = () => {
        setCursors([]);
    }

    const getItineraries = (forwardCursor: boolean = true) => {
        setLoading(true)
        httpClient.post("/itineraries/list", {
            city: city,
            published: type === ItineraryType.Public ? true : false,
            limit: pageSize,
            keyword: keyword ? keyword : null,
            slugCursor: getCursor(forwardCursor),
        })
            .then(res => {
                if (forwardCursor) {
                    pushCursor(res.data.slugCursor);
                } else {
                    popCursor();
                }
                setItineraries(res.data.itineraries);
            }).finally(() => setLoading(false));
    }


    useEffect(() => {
        getItineraries();
    }, [type, city, keyword])

    return <>
        <Stack margin={1} spacing={1} direction={"column"}>
            <Stack margin={1} spacing={1} direction={"row"}>
                <TextField label={"find a trip"} onChange={(e) => {
                    setKeyword(e.target.value);
                    clearCursors();
                }}></TextField>
                <CitySelectMinimal label={"City"} onInput={(c) => {
                    setCity(c);
                    clearCursors();
                }}></CitySelectMinimal>
                <Select value={type} onChange={(e) => {
                    setType(e.target.value as ItineraryType);
                    clearCursors();
                }}>
                    <MenuItem value={ItineraryType.Mine}>{ItineraryType.Mine.toString()}</MenuItem>
                    <MenuItem value={ItineraryType.Public}>{ItineraryType.Public.toString()}</MenuItem>
                </Select>
                <IconButton onClick={() => navigate("/")}>
                    <AddIcon />
                </IconButton>
            </Stack>
            <Divider />
            {loading && <LinearProgress color="primary"></LinearProgress>}
            <Stack margin={1} spacing={1} direction={"column"} flexWrap={"wrap"}>
                {itineraries.map(i => {
                    return <>
                        <ItinerarySummaryCard itinerary={i} />
                    </>
                })}
            </Stack>
            <Stack direction={"row"}>
                <Button startIcon={<ArrowLeftIcon />} disabled={cursors.length < 2} onClick={() => {
                    getItineraries(false);
                }}>Prev</Button>
                <Button endIcon={<ArrowRighIcon />} disabled={itineraries.length < pageSize} onClick={() => {
                    getItineraries(true);
                }}>Next</Button>
            </Stack>
        </Stack>
    </>
}