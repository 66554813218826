import { Publish } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Itinerary } from '../core/types';
import { HttpContext } from './HttpClientProvider';
import { ToastContext } from './ToastContextProvider';
import { UserContext } from './UserContext';

export interface Props {
    itinerary: Itinerary;
}

export default function ItineraryActions({ itinerary }: Props) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const { addToast } = useContext(ToastContext);
    const { httpClient } = useContext(HttpContext);

    const handlePublish = () => {
        handleClose();
        const newState = !itinerary.published;
        httpClient.post("/auth/itineraries/publish", {
            id: itinerary.id,
            published: newState
        }).then(res => {
            if (res.data.id) {
                addToast({ message: `Itinerary ${newState ? `published` : `publish reverted`}!`, severity: "success" })
                itinerary.published = newState;
            }
        }).catch((e) => {
        })
    }

    const handleDelete = () => {
        httpClient.post("/auth/itineraries/delete", {
            id: itinerary.id
        }).then(res => {
            addToast({ message: "Itinerary deleted!", severity: "success" })

        }).catch((e) => {
        });
        navigate("/trips")
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isOwnerUser = (): boolean => {
        return user?.id === itinerary.userId;
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


    return (
        <div>
            {isOwnerUser() && <>
                <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handlePublish}>
                        <ListItemText>{itinerary.published ? `Undo Publish` : `Publish`}</ListItemText>
                        <ListItemIcon>
                            <Publish fontSize='small' />
                        </ListItemIcon>
                    </MenuItem>
                    <MenuItem onClick={handleDelete}>
                        <ListItemText>Delete</ListItemText>
                        <ListItemIcon>
                            <DeleteIcon fontSize='small' />
                        </ListItemIcon>
                    </MenuItem>
                </Menu>
            </>}
        </div>
    );
}
