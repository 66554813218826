import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LuggageIcon from '@mui/icons-material/Luggage';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton, ListItemText, MenuItem, MenuList, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import UserMenuItem from './UserMenuItem';
import AttractionsIcon from '@mui/icons-material/Attractions';

export interface Props {
    themeToggle: React.ReactNode;
}

export default function AppMenu({ themeToggle }: Props) {
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const navigateTo = (path: string) => {
        setOpen(false);
        navigate(path);
    }
    return <>
        <Stack>
            <Stack spacing={1} justifyContent={"space-between"} width={"100%"} direction={"row"} sx={{
                backgroundColor: theme.palette.secondary.main,
                margin: 'auto',
            }}>
                <Stack spacing={1} alignItems={"center"} direction={"row"}>
                    <IconButton onClick={() => setOpen(true)}>
                        <MenuIcon sx={{color:"white"}} />
                    </IconButton>
                    <AttractionsIcon></AttractionsIcon>
                    <Typography color={"white"} variant="h6">Journey Juggler</Typography>
                </Stack>
                <Stack spacing={1} direction={"row"}>
                    {themeToggle}
                    <UserMenuItem />
                </Stack>
            </Stack>
            <Drawer open={open} onClose={() => setOpen(false)}>
                <Box sx={{ width: 250 }} role="presentation" onClick={() => setOpen(false)}>
                    <Stack spacing={1} direction={"column"}>
                        <MenuList>
                            <MenuItem onClick={() => navigateTo("/")}>
                                <HomeIcon />
                                <ListItemText>Home</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => navigateTo("/trips")}>
                                <LuggageIcon />
                                <ListItemText>Trips</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => navigateTo("/about")}>
                                <InfoIcon />
                                <ListItemText>About</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Stack>
                </Box>
            </Drawer>
        </Stack>
    </>
}