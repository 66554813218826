import { TextField } from "@mui/material";
import React from "react";

export interface Props {
    keywordChange: (keyword: string) => void;
}

export default function SearchPlace({ keywordChange }: Props) {
    const [inputValue, setInputValue] = React.useState("");


    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            keywordChange(inputValue);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [inputValue, 500]);
    return <>
        <TextField sx={{ height: "40px" }} focused color="primary" variant="outlined" size="small" label={"Search"} onChange={(e) => {
            setInputValue(e.target.value)
            
        }}></TextField>
    </>
}